<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入名称" clearable suffix-icon="el-icon-search" v-model="name"></el-input>
	    <el-input style="width: 200px" placeholder="请输入标的编号" clearable suffix-icon="el-icon-search" v-model="bdid"></el-input>
	  <el-select v-model="state" placeholder="状态">
	    <el-option label="已报名" value="已报名"></el-option>
	    <el-option label="已结束" value="已结束"></el-option>
		<el-option label="已报价" value="已报价"></el-option>
		<el-option label="待处理" value="待处理"></el-option>
	  </el-select>
	  <el-select v-model="istrue" placeholder="是否中标">
	    <el-option label="已中标" value="是"></el-option>
	    <el-option label="未中标" value="否"></el-option>
	  </el-select>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>


    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
<!--     <el-table-column prop="id" label="ID" width="80" sortable></el-table-column> -->
	  <el-table-column prop="bdid" label="标的编号"></el-table-column>
      <el-table-column prop="gsname" label="公司名称"></el-table-column>
      <el-table-column prop="biaodiname" label="标的名称"></el-table-column>
      <el-table-column prop="state" label="标的状态" sortable></el-table-column>
	  <el-table-column prop="remark" label="应标数量"></el-table-column>
	   <el-table-column style="display: none;" prop="istrue" label="是否中标"></el-table-column>

    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "Biaodigl",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      name: "",
	  state:"",
	  istrue:"",
	  bdid:"",
      form: {},
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/biaodigl/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
		  state:this.state,
		  istrue:this.istrue,
		  bdid:this.bdid
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    save() {
      this.request.post("/biaodigl", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      this.$nextTick(() => {
        if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
      })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
       this.$nextTick(() => {
         if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
       })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/biaodigl/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.name = ""
	  this.istrue = ""
	  this.state = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open("http://localhost:9090/biaodigl/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    },changeState(obj) {
          this.entity = obj;
          this.save();
    },focusChange () {
      this.timeFlag = ''
    },
    blurChange () {
      this.timeFlag = '2'
    },
    curChange (id) {
      this.pg = id
      this.getlists()
    },getlists(){
		load()
	},// 导出数据
	exportToExcel() {
	 	
		
     if (this.tableData.length === 0) {
       this.$message.warning('没有数据可导出');
       return;
     } else {
       this.handelExcel()
     }
   },
   handelExcel() {
     require.ensure([], () => {
       const { export_json_to_excel } = require('@/vendor/Export2Excel.js')//注意这个Export2Excel路径
       const tHeader = ['序号', '报价单位', '标的名称','报价', '应标数量','是否中标'] // 上面设置Excel的表格第一行的标题
       const filterVal = ['bdid', 'gsname', 'biaodiname','baojia', 'remark','istrue'] // tableData里对象的属性key值
       const list = this.tableData //把要导出的数据tableData存到list
       const data = this.formatJson(filterVal, list)
       export_json_to_excel(tHeader, data, '标的信息')
     })
   },
   //格式转换,不需要改动
   formatJson(filterVal, jsonData) {
     return jsonData.map(v => filterVal.map(j => v[j]))
   },
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
